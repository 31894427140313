import React, { Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Card from './card'

const WhatsNew = () => (
  <StaticQuery
    query={graphql`
      query WhatsNewQuery {
        markdownRemark(frontmatter: { name: { eq: "index" } }) {
          frontmatter {
            whatisnew {
              title
              new {
                title
                subtitle
                description
                image {
                  childImageSharp {
                    gatsbyImageData(quality: 90) 
                  }
                }
                placement
                alttext
                link
              }
            }
          }
        }
      }
    `}
    render={post => (
      <section className="bg-gray-light">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large">
                {post.markdownRemark.frontmatter.whatisnew.title}
              </h2>
              {post.markdownRemark.frontmatter.whatisnew.new && (
                <MDBRow className="pt-4">
                  {post.markdownRemark.frontmatter.whatisnew.new.map((whatsnew, index) => (
                    <Fragment key={index}>
                      <Card
                        collg="4"
                        colmd="6"
                        height="7.5rem"
                        title={whatsnew.title}
                        subtitle={whatsnew.subtitle}
                        description={whatsnew.description}
                        image={whatsnew.image.childImageSharp.gatsbyImageData}
                        alt={whatsnew.alttext}
                        placement={whatsnew.placement}
                        link={whatsnew.link}
                        descriptionClass="text-card-small"
                      />
                    </Fragment>
                  ))}
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )}
  />
)
export default WhatsNew