import Cookie from 'universal-cookie' 

export function targetingEnabled() {
  const cookie = new Cookie()
  const consent = cookie.get("CookieScriptConsent")

  if (consent?.categories && consent?.categories.includes("targeting")) {
    return true
  } else {
    return false
 }
}